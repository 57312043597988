angular.module('myApp.ourCommitments', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/our-commitments/:local', {
    templateUrl: 'templates/ourcommitments-template.html',
    controller: 'OurCommitmentsCtrl'
  });
        /*$routeProvider.when('/our-commitments', {
            templateUrl: 'templates/ourcommitments-template.html',
            controller: 'OurCommitmentsCtrl'
        });*/

}])

.controller('OurCommitmentsCtrl', ['$window', '$timeout', '$scope', function($window, $timeout, $scope) {
  /*
    pour scroller vers l'ecran suivant
  */
  this.scrollDown = function(anchor){
     TweenMax.to($window, 1, {scrollTo:{y:$('#' + anchor).offset().top}});
  };
  this.scrollToItem = function(anchor, $event){

      $scope.$watch(
          function() {
              var target = $($event.target);
              return target.hasClass('isSelected');
          },
          function () {
              if($window.innerWidth <= 768){
                  $timeout(function() {
                      $('body, html').animate({
                          scrollTop: $('#' + anchor).offset().top
                      }, 500);
                  }, 1000);
              }
          }
      );
  };

}])

.controller('CommitmentsJobCtrl', ['$scope', '$timeout', '$anchorScroll', '$location', function($scope, $timeout, $anchorScroll, $location) {
    this.jobId = 1;
    this.jobList = [
        { url: '/templates/pilliar-1-template.html' },
        { url: '/templates/pilliar-2-template.html' },
        { url: '/templates/pilliar-3-template.html' },
        { url: '/templates/pilliar-4-template.html' },
        { url: '/templates/pilliar-5-template.html' },
        { url: '/templates/pilliar-6-template.html' }
    ];
    this.actualJob = this.jobList[0].url;
    this.setJob = function(val){
        this.jobId = val;
        this.actualJob = this.jobList[val-1].url;
    };
    $scope.show = true;
    $scope.afterShow = function(index){};


}]);


