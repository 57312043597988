angular.module('myApp.welcome', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/welcome/:local', {
    templateUrl: 'templates/welcome-template.html',
    controller: 'WelcomeCtrl'
  });
       /* $routeProvider.when('/welcome/', {
            templateUrl: 'templates/welcome-template.html',
            controller: 'WelcomeCtrl'
        });*/
}])

.controller('WelcomeCtrl', ['$window', '$scope', '$routeParams', '$translate', function($window, $scope, $routeParams, $translate) {

  /*
    pour scroller vers l'ecran suivant
  */
  this.scrollDown = function(anchor){

      $('body, html').animate({
          scrollTop: $('#' + anchor).offset().top
      }, 1000);
  };
}]);

