angular.module('myApp.rexelsCulture', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/rexels-culture/:local', {
    templateUrl: 'templates/rexelsculture-template.html',
    controller: 'RexelsCultureCtrl'
  });
        /*$routeProvider.when('/rexels-culture', {
            templateUrl: 'templates/rexelsculture-template.html',
            controller: 'RexelsCultureCtrl'
        });*/
}])

.controller('RexelsCultureCtrl', ['$window', '$timeout', function($window, $timeout) {
  /*
    pour scroller vers l'écran suivant
  */
  this.scrollDown = function(){
    //TweenMax.to($window, 1, {scrollTo:{y:$window.innerHeight}});
    $('body, html').animate({
        scrollTop: $window.innerHeight
    }, 1000);
  };
    this.scrollToItem = function(anchor){
        if($window.innerWidth <= 768){
            $timeout(function() {
                $('body, html').animate({
                    scrollTop: $('#' + anchor).offset().top
                }, 500);
            }, 1000);
        }
    };
}])

.controller('JobCtrl', ['$scope', '$timeout', '$anchorScroll', '$location', function($scope, $timeout, $anchorScroll, $location) {
  this.jobId = 1;
  this.jobList = [
    { url: '/templates/jobstory-5-template.html' },
    { url: '/templates/jobstory-1-template.html' },
    { url: '/templates/jobstory-3-template.html' },
    { url: '/templates/jobstory-2-template.html' },
    { url: '/templates/jobstory-4-template.html' },
    { url: '/templates/jobstory-6-template.html' }
  ];
  this.actualJob = this.jobList[0].url;
  this.setJob = function(val){
    this.jobId = val;
    this.actualJob = this.jobList[val-1].url;
  };
  $scope.show = true;
  $scope.afterShow = function(index){};

}])

.animation('.animate-job', [function(){
  return {
    enter: function(element, className, done){
      TweenMax.to(element, 1, {alpha: 1, ease:Expo.easeOut, onComplete:done});
    },
    leave: function(element, className, done){
      TweenMax.to(element, .5, {alpha: 0, ease:Expo.easeOut, onComplete:function(){
        element.remove();
      }});
    }
  }
}]);

