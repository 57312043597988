angular.module('myApp.allAbout', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/all-about/:local', {
    templateUrl: 'templates/allabout-template.html',
    controller: 'AllAboutCtrl'
  });
       /* $routeProvider.when('/all-about', {
            templateUrl: 'templates/allabout-template.html',
            controller: 'AllAboutCtrl'
        });*/
}])

.controller('AllAboutCtrl', ['$window', function($window) {
  /*
    pour scroller vers l'écran suivant
  */
  this.scrollDown = function(anchor){
    //TweenMax.to($window, 1, {scrollTo:{y:$('#' + anchor).offset().top}});
    $('body, html').animate({
        scrollTop: $('#' + anchor).offset().top
    }, 1000);
  };
}])

.directive('equalCols', ['$window', function($window){
  return {
    restrict:'A',
    scope:{ //créé un scope privé, la directive n'aura pas accès au scope parent
      'equalCols': '='
    },
    link: function(scope, element, attrs) {
      scope.$on('resize::resize', function(event, args){
        /*if($window.innerWidth > 768 && $window.innerWidth <= 1024) {
          var currentFigureHeight = element.find('.AllAboutArticle-figure').height();
          element.find('.AllAboutArticle-content').css('height', 100+'%');
          element.height( currentFigureHeight );
        } else {
          element.find('.AllAboutArticle-content').height( 'auto' );
          element.height( 'auto' );
        }*/
      });
    }
  };
}])

.directive('blockOutside', ['$window', function($window){
  return {
    restrict:'A',
    scope:{ //créé un scope privé, la directive n'aura pas accès au scope parent
      'blockOutside': '='
    },
    link: function(scope, element, attrs){

      function blockOutsidePos(){
        if($window.innerWidth > 768) {
          var percentTopPos = parseInt( (element.find('.mobileArticleFigureContainer').outerWidth(true) * 701 / 1920) * .6),
              figureHBorder = parseInt( element.find('.AllAboutArticle-figure').css('border-bottom-width') );
              figureH = ( element.find('.mobileArticleFigureContainer').outerWidth(true) * 701 / 1920 ) + figureHBorder;
              contentH = element.find('.AllAboutArticle-content').outerHeight(true),
              greyBGH = (contentH + percentTopPos) - figureH;

          element.find('.AllAboutArticle-content').css({
            top: percentTopPos
          });
          element.find('.bg-greylight').css({
            height: greyBGH,
            top: figureH
          });
        } else {
          element.find('.AllAboutArticle-content').css({
            top: 'auto'
          });
          element.find('.bg-greylight').css({
            height: 'auto',
            top: 'auto'
          });
        }
      }

      blockOutsidePos();

      scope.$on('resize::resize', function(event, args){
        blockOutsidePos();
      });
    }
  };
}])

/*
.directive('mobileToLandscape', ['$window', function($window){
  return {
    restrict:'A',
    scope:{ //créé un scope privé, la directive n'aura pas accès au scope parent
      'mobileToLandscape': '='
    },
    link: function(scope, element, attrs){
      scope.$on('resize::resize', function(event, args){
        if($window.innerWidth <= 768) {
          element.find('.AllAboutArticle-figure').css({
            height: $window.innerWidth*9/16
          });
        } else {
          element.find('.AllAboutArticle-figure').css({
            height: 'auto'
          });
        }
      });
    }
  };
}])*/

.directive('displayHeight', ['$window', function($window){
    return {
        restrict:'A',
        scope:{ //créé un scope privé, la directive n'aura pas accès au scope parent
            'mobileToLandscape': '='
        },
        link: function(scope, element, attrs){

            function resizeBlocks() {
                if($window.innerWidth <= 885) {
                    element.css({
                        height: $window.innerWidth*9/16
                    });
                }
                else {
                    element.css({
                        height: '100%'
                    });
                }
            }
            resizeBlocks();

            scope.$on('resize::resize', function(event, args){
                resizeBlocks()
            });
        }
    };
}]);
