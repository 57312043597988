angular.module('myApp.home', ['ngAnimate', 'ngRoute']).

config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/home/:local', {
    templateUrl: 'templates/home-template.html',
    controller: 'HomeCtrl'
  });
        /*$routeProvider.when('/home/', {
            templateUrl: 'templates/home-template.html',
            controller: 'HomeCtrl'
        });*/
}]).

controller('HomeCtrl', ['$window', '$scope', '$routeParams', '$translate', function($window, $scope, $routeParams, $translate) {

}]).

controller('HomeArticle', ['$scope', function($scope) {
  /*
    gestion de l'apparition du rectangle coloré sur chaque bloc de la home
  */
  this.overlayVisible = false;
  this.toggleOverlay = function(doIt){
    if(doIt) { this.overlayVisible = !this.overlayVisible; }
  };
}]).

directive('overlayVisible', ['$animate', function($animate) {
  return function(scope, element, attrs){
    $(element).css('transform', 'matrix(1,0,0,1,0,'+(+$(element).height()-5)+')');
    scope.$on('resize::resize', function(event, args){
      $(element).css('transform', 'matrix(1,0,0,1,0,'+(+$(element).height()-5)+')');
    });
    scope.$watch(attrs.overlayVisible, function(newVal){
      if(newVal) {
        $animate.addClass(element, 'overlayDisplayed');
      } else {
        $animate.removeClass(element, 'overlayDisplayed');
      }
    });
  }
}]).

/*
  animation de l'apparition/disparition du rectangle coloré sur chaque bloc de la home
*/
animation('.overlayDisplayed', [function(){
  return {
    addClass: function(element, className, done){
      TweenMax.to(element, .8, {y: 0, ease:Expo.easeOut, onComplete:done});
    },
    removeClass: function(element, className, done){
      TweenMax.to(element, .8, {y: $(element).height() - 5, ease:Expo.easeOut, onComplete:done});
    }
  }
}]);
