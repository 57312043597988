angular.module('myApp.successStories', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/success-stories/:local', {
    templateUrl: 'templates/successstories-template.html',
    controller: 'SuccessStoriesCtrl'
  });
        /*$routeProvider.when('/success-stories', {
            templateUrl: 'templates/successstories-template.html',
            controller: 'SuccessStoriesCtrl'
        });*/
}])

.controller('SuccessStoriesCtrl', ['$window', '$timeout', function($window, $timeout) {
  /*
    pour scroller vers l'écran suivant
  */
  this.scrollDown = function(){
    //TweenMax.to($window, 1, {scrollTo:{y:$window.innerHeight}});
    $('body, html').animate({
        scrollTop: $window.innerHeight
    }, 1000);
  };
    this.scrollToItem = function(anchor){
        if($window.innerWidth <= 768){
            $timeout(function() {
                $('body, html').animate({
                    scrollTop: $('#' + anchor).offset().top
                }, 500);
            }, 1000);
        }
    };
}])

.controller('StoryCtrl', ['$scope', '$timeout', '$anchorScroll', '$location', '$window', function($scope, $timeout, $window) {
  this.storyId = 1;
  this.storyList = [
    { url: '/templates/successstories-1-template.html' },
    { url: '/templates/successstories-2-template.html' },
    { url: '/templates/successstories-3-template.html' },
    { url: '/templates/successstories-4-template.html' }
  ];

  this.actualStory = this.storyList[0].url;
  this.setStory = function(val){
    this.storyId = val;
    this.actualStory = this.storyList[val-1].url;
    // tentative de scroll après remplissage
    // $location.hash('item-'+val);
    // $anchorScroll('item-'+val);
    /*setTimeout(function(){
      $location.hash('item-'+val);
      $anchorScroll();
    }, 1000);*/
  };
  $scope.show = true;
  $scope.afterShow = function(index){
    // $location.hash('item2');
    // $anchorScroll();
  };

}])

//directive my-show qui gère le scroll sur le titre après avoir affichier le contenu
.directive("myShow", ['$animate', function($animate){ //$animate = module angular car dependance
  return {
    restrict:'A', //my-show sera comprit que sous forme d'attribut html
    scope:{ //créé un scope privé, la directive n'aura pas accès au scope parent
      'myShow': '=', // voir la doc plus tard
      'afterShow': '&'
    },
    link: function(scope, element, attrs){ //element = element html où se trouve l'attribut my-show
      scope.$watch('myShow', function(newVal, oldVal){
        if(newVal === true){
          $animate.removeClass(element, 'ng-hide').then(scope.afterShow(2));
        }
      })
    }
  };
}])

.animation('.animate-story', [function(){
  return {
    enter: function(element, className, done){
      TweenMax.to(element, 1, {alpha: 1, ease:Expo.easeOut, onComplete:done});
    },
    leave: function(element, className, done){
      TweenMax.to(element, .5, {alpha: 0, ease:Expo.easeOut, onComplete:function(){
        element.remove();
      }});
    }
  }
}]);

// .animation('.animate-mobile-story', [function(){
//   return {
//     enter: function(element, className, done){
//       // animation d'ouverture
//     },
//     leave: function(element, className, done){
//       // animation de fermeture
//     }
//   }
// }]);
