angular.module('myApp.rexelAndYou', ['ngRoute', 'ngAnimate'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/rexel-and-you/:local', {
    templateUrl: 'templates/rexelandyou-template.html',
    controller: 'RexelAndYouCtrl'
  });
        /*$routeProvider.when('/rexel-and-you', {
            templateUrl: 'templates/rexelandyou-template.html',
            controller: 'RexelAndYouCtrl'
        });*/
}])

.controller('RexelAndYouCtrl', ['$window', '$scope', function($window, $scope) {
  /*
    pour scroller vers l'ecran suivant
  */
  this.scrollDown = function(anchor){
     //TweenMax.to($window, 1, {scrollTo:{y:$('#' + anchor).offset().top}});
     $('body, html').animate({
         scrollTop: $('#' + anchor).offset().top
     }, 1000);
  };
}])

/*
  Controller gérant le fonctionnement de la carte
*/
.controller('RexelAndYouWorldMapCtrl', ['$scope', '$window', function($scope, $window) {

  this.tweetList = [
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'US',
      text: 'I started as a storeman, worked my way through counterhand, purchasing, assistant manager. Rexel have lots of opportunities to move up or in what direction you want your career to go.', country: 'US' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'US',
      text: 'I can call on any branch, my RM or RSO and get support & training at any time – and there is a land of varied experience out there.', country: 'US' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'US',
      text: 'Rexel is a Fortune 500 company, and the largest lighting integrator in the world. It makes you feel honoured to work here and be part of it.', country: 'US' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'US',
      text: 'My second week in the job I had a few meetings with a rep that used to do my job, and is now in the position I want to end up in. He was able to help me on how to best do my current job, and how to improve myself to do his role, and we still talk weekly to this day', country: 'US' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'US',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'US' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'IT',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'IT' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'IT',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'IT' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'IT',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'IT' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'BR',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'BR' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'BR',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'BR' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'BR',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'BR' },
    { img: 'images/tweet-img.jpg', name: 'rexel', tag: '@media', time: 'BR',
      text: 'My ideas for client events/workshop are appreciated and get implemented.', country: 'BR' }
  ];
  this.countryList = [
    {name: 'United States', position: {top: '20%', left: '80%'}, tag: 'US'},
    {name: 'Brazil', position: {top: '0', left: '0'}, tag: 'BR'},
    {name: 'Italia', position: {top: '0', left: '0'}, tag: 'IT'}
  ];

  $scope.selectedCountry = this.countryList[0];

  /*
    pour scroller dans la liste des tweets
  */
  this.scrollDown = function(){
    $scope.$emit('scroll:down');
  };
  /*
    pour filtrer les tweets en fontion du pays sélectionné dans '$scope.selectedCountry'
  */
  $scope.searchCountry = function(item){
    if(item.country === $scope.selectedCountry.tag) {
      return true;
    } else {
      return false;
    }
  };
}])

/*
  afin de gérer l'arrêt de la lecture d'une vidéo, en cas de changement de page ou d'ecran..
*/
.directive('listenStopOrPlay', [function(){
  return function(scope, element, attrs){
    scope.$on('video::play', function(e){
      element[0].play();
    });
    scope.$on('video::stop', function(e){
      element[0].pause();
    });
  }
}])

/*
  création de la carte, attention à passer les pays dans series.regions.values
*/
.directive('map', ['$window', function($window) {
  return {
    link: function (scope, element, attrs) {
      element.empty();
      $(element).vectorMap({
        zoomOnScroll: false,
        backgroundColor: 'transparent',
        regionStyle: {
          initial: {
            fill: '#cccccc'
          }
        },
        series: {
          regions: [{
            values: {"US":0, "IT":0, "BR":0},
            scale: ['#0071A4'],
            normalizeFunction: 'polynomial'
          }]
        },
        onRegionClick: function(e, code){
          var newCountry = _.find([
            {name: 'United States', position: {top: '20%', left: '80%'}, tag: 'US'},
            {name: 'Brazil', position: {top: '0', left: '0'}, tag: 'BR'},
            {name: 'Italia', position: {top: '0', left: '0'}, tag: 'IT'}
            ], { 'tag': code });
          if (newCountry) {
            scope.selectedCountry = newCountry;
            scope.$apply();
          }
        },
        onViewportChange: function(e){
          element.height($(window).height());
          element.width(($(window).width()-320)*.9);
          element.css('marginLeft', '5%');
        }
      });
    }
  }
}])

/*
  Sert à calculer la hauteur du bloc de listing des tweets
*/
.directive('onResize', [function(){
  return function(scope, element, attrs){
    scope.$on('resize::resize', function(event, args){
      if($(window).width()>768){
        element.height($(window).height()-65);
      } else {
        element.height('auto');
      }
    });
  }
}])

/*
  notifié par 'RexelAndYouWorldMapCtrl.scrollDown', scroll dans la liste des tweets pour afficher les suivants
*/
.directive('scrollTo', [function() {
  return function(scope, element, attrs){
    scope.$on('scroll:down', function(e, args){
      TweenMax.to(element, 1, {scrollTo:{y:'+='+($(window).height() - 40)}});
    });
  }
}])

/*
  fixe un scroll jquery 'mCustomScrollbar' sur l'élément sur laquelle cette directive est placé
*/
.directive('scrollable', [function(){
  return function(scope, element) {
    $(element).mCustomScrollbar();
  };
}]);
