angular.module('myApp.valueChain', ['ngRoute'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/valuechain/:local', {
    templateUrl: 'templates/valuechain-template.html',
    controller: 'ValueChainCtrl'
  });
    /*$routeProvider.when('/valuechain', {
        templateUrl: 'templates/valuechain-template.html',
        controller: 'ValueChainCtrl'
    });*/
}])

.controller('ValueChainCtrl', ['$scope', function($scope) {
  /*
    gestion du player vidéo
  */
  this.playerDisplayed = false;
  this.playVideo = function(){
    this.playerDisplayed = true;
    $scope.$broadcast('video::play');
  };
  this.stopVideo = function(){
    $scope.$broadcast('video::stop');
    this.playerDisplayed = false;
  };
}])

/*
  afin de gérer l'arrêt de la lecture d'une vidéo, en cas de changement de page ou d'ecran..
*/
.directive('listenStopOrPlay', [function(){
  return function(scope, element, attrs){
    scope.$on('video::play', function(e){
      element[0].play();
    });
    scope.$on('video::stop', function(e){
      element[0].pause();
    });
  }
}]);
