angular.module('myApp.rexelToday', ['ngRoute', 'gilbox.sparkScroll'])

.config(['$routeProvider', function($routeProvider) {
  $routeProvider.when('/rexel-today/:local', {
    templateUrl: 'templates/rexeltoday-template.html',
    controller: 'RexelTodayCtrl'
  });
        /*$routeProvider.when('/rexel-today', {
            templateUrl: 'templates/rexeltoday-template.html',
            controller: 'RexelTodayCtrl'
        });*/
}])

.controller('RexelTodayCtrl', ['$scope', '$window', 'sparkSetup', function($scope, $window, sparkSetup) {
  /*
    active le module d'animation en fonction du scroll 'spark-scroll'
  */
  sparkSetup.enableInvalidationInterval();
  sparkSetup.debug = true;

  /*
    On desactive Spark Scroll lorsqu'on la taille de la fenetre du navigateur est inférieure à une taille donnée
  */
  this.isMobile = function (screenWidth) {
    if(window.innerWidth < screenWidth) {
      sparkSetup.disableSparkScroll = true;
      sparkSetup.disableSparkScrollAnimate = true;
    }else {
      sparkSetup.disableSparkScroll = false;
      sparkSetup.disableSparkScrollAnimate = false;
    }
  }

  /*
    pour scroller vers un élément spécifique, dans notre cas, '.Whoweare' par exemple
  */
  this.scrollDown = function(elem){
    if(elem) {
      //TweenMax.to($window, 1, {scrollTo:{y:$(elem).position().top+$(window).height()}});
      $('body, html').animate({
        scrollTop: $(elem).position().top+$(window).height()
      }, 1000);
    } else {
      //TweenMax.to($window, 1, {scrollTo:{y:$window.innerHeight}});
      $('body, html').animate({
          scrollTop: $window.innerHeight
      }, 1000);
    }
  };

  /*
    gestion du player vidéo
  */
  this.playerDisplayed = false;
  this.playVideo = function(){
    this.playerDisplayed = true;
    $scope.$broadcast('video::play');
  };
  this.stopVideo = function(){
    $scope.$broadcast('video::stop');
    this.playerDisplayed = false;
  };
}])

/*
  afin de gérer l'arrêt de la lecture d'une vidéo, en cas de changement de page ou d'ecran..
*/
.directive('listenStopOrPlay', [function(){
  return function(scope, element, attrs){
    scope.$on('video::play', function(e){
      element[0].play();
    });
    scope.$on('video::stop', function(e){
      element[0].pause();
    });
  }
}]);

/*
.directive('scroll', [function(){
  return function(scope, element, attrs){

      function getScrollOffsets(w) {
       
        w = w || window;
        
        if (w.pageXOffset != null) return {
          x: w.pageXOffset,
          y: w.pageYOffset
        };
      }

      angular.element($window).bind("scroll", function () {
        var offset = getScrollOffsets($window);
        if (offset.y >= 50) {
        scope.boolChangeClass = true;
        } else {
        scope.boolChangeClass = false;
        }
        scope.$apply();
      });
  }
}]);*/







