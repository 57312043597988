// Declare app level module which depends on views, and components
angular.module('templates', []);
angular.module('myApp', [
  'ngAnimate',
  'ngRoute',
  'pascalprecht.translate',
  'myApp.home',
  'myApp.welcome',
  'myApp.rexelToday',
  'myApp.valueChain',
  'myApp.successStories',
  'myApp.rexelAndYou',
  'myApp.rexelsCulture',
  'myApp.ourCommitments',
  'myApp.allAbout',
  'sticky'
]).


run(['$rootScope', '$location', '$window',
    function($rootScope, $location, $window, $scope) {
        $rootScope.$on('$routeChangeSuccess',
            function(event) {
                if (!$window.ga) {
                    return;
                }
                $window.ga('send', 'pageview', {
                    page: $location.path()
                });
            });
    }
]).

config(['$locationProvider', '$routeProvider', '$translateProvider', function($locationProvider, $routeProvider, $translateProvider) {

  /*
    Configuration du module 'angular-translate'
  */
  $translateProvider.useStaticFilesLoader({
    prefix: 'lang/locale-',
    suffix: '.json'
  });
  $translateProvider.preferredLanguage('en');
  /*
    Ajout d'un prefix pour le référenement (voir les conseils de Google pour réfenrencer une SPA)
  */
  $locationProvider.hashPrefix('!');
  /*
    En l'absence d'une route correct, je renvoi automatiquement vers la home page
  */
  $routeProvider.otherwise({redirectTo: '/home/en'});


}]).

controller('AppCtrl', ['$rootScope', '$scope', '$window', '$timeout', '$translate', '$location', '$routeParams', function($rootScope, $scope, $window, $timeout, $translate, $location, $routeParams){

  var self = this;
  //$scope.local = $routeParams.local;
  $scope.urlLang = "en";

  /*
    A chaque changement de langue via '$translate.use(keylang);', le rootScope reçoit l'évènement '$translateChangeSuccess',
    j'écoute ici cet évènement pour traduire à nouveau les titres et sous titres de chaque bloc du menu.
  */
  $rootScope.$on('$translateChangeSuccess', function() {
    $translate([
        'NAV.LINK_1.TITLE', 'NAV.LINK_1.SUBTITLE', 'NAV.LINK_2.TITLE', 'NAV.LINK_2.SUBTITLE',
        'NAV.LINK_3.TITLE', 'NAV.LINK_3.SUBTITLE', 'NAV.LINK_4.TITLE', 'NAV.LINK_4.SUBTITLE',
        'NAV.LINK_5.TITLE', 'NAV.LINK_5.SUBTITLE', 'NAV.LINK_6.TITLE', 'NAV.LINK_6.SUBTITLE',
        'NAV.LINK_7.TITLE', 'NAV.LINK_7.SUBTITLE', 'NAV.LINK_8.TITLE', 'NAV.LINK_8.SUBTITLE',
        'CONTENT.VIDEOS.TODAY', 'CONTENT.IMAGES.TODAY', 'CONTENT.IMAGES.TODAY_ACTIVITY', 'CONTENT.IMAGES.TODAY_CUSTOMERS',
        'CONTENT.IMAGES.TODAY_RESULTS_1', 'CONTENT.IMAGES.TODAY_RESULTS_2',
        'CONTENT.IMAGES.TODAY_CLIENTS', 'CONTENT.IMAGES.ENERGY', 'CONTENT.PDF.PROMISE', 'CONTENT.PDF.GUIDE', 'CONTENT.PDF.SAFETY', 'CONTENT.VIDEOS.VALUECHAIN'

    ])
      .then(function(translations) {
            /* on recuper la locale pour l'injecter ensuite dans les liens ci-dessous*/
            //console.log('-----------------');
            $scope.local = $routeParams.local;
            if($scope.local == null) $scope.local = '';
            if($scope.local != null) $scope.local = '/' + $scope.local;
            //console.log('scope.local: ' + $scope.local);

            $scope.urlLang = $scope.getUrlLang();
            //console.log('scope.urlLang: ' + $scope.urlLang);

            $scope.urlGood = $scope.urlLang;
            $scope.urlGood = '/' + $scope.urlGood;
            //console.log('scope.urlGood: ' + $scope.urlGood);

          /*
            Le tableau navLinks est remplis des données nécessaires à l'affichage du menu et de la home.
          */
          self.navLinks = [
            { title: translations['NAV.LINK_1.TITLE'],
              subtitle: translations['NAV.LINK_1.SUBTITLE'], fade: false,
              url: 'welcome' + $scope.urlGood, size: 'large', overlayColor: 'c-azure' },
            { title: translations['NAV.LINK_2.TITLE'],
              subtitle: translations['NAV.LINK_2.SUBTITLE'], fade: false,
              url: 'valuechain' + $scope.urlGood, size: 'large', overlayColor: 'c-pink' },
            { title: translations['NAV.LINK_3.TITLE'],
              subtitle: translations['NAV.LINK_3.SUBTITLE'], fade: false,
              url: 'rexel-today' + $scope.urlGood, size: 'compact', overlayColor: 'c-salmon' },
            { title: translations['NAV.LINK_4.TITLE'],
              subtitle: translations['NAV.LINK_4.SUBTITLE'], fade: false,
              url: 'success-stories' + $scope.urlGood, size: 'compact', overlayColor: 'c-purple' },
            { title: translations['NAV.LINK_5.TITLE'],
              subtitle: translations['NAV.LINK_5.SUBTITLE'], fade: false,
              url: 'all-about' +  $scope.urlGood, size: 'compact', overlayColor: 'c-blue' },
            { title: translations['NAV.LINK_6.TITLE'],
              subtitle: translations['NAV.LINK_6.SUBTITLE'], fade: false,
              url: 'our-commitments' + $scope.urlGood, size: 'compact', overlayColor: 'c-green' },
            { title: translations['NAV.LINK_7.TITLE'],
              subtitle: translations['NAV.LINK_7.SUBTITLE'], fade: false,
              url: 'rexels-culture' +  $scope.urlGood, size: 'compact', overlayColor: 'c-lightblue' },
            { title: translations['NAV.LINK_8.TITLE'],
              subtitle: translations['NAV.LINK_8.SUBTITLE'], fade: false,
              url: 'rexel-and-you' + $scope.urlGood, size: 'compact', overlayColor: 'c-sand' }
          ];

          $scope.content = {
              videos: {
                  today: translations['CONTENT.VIDEOS.TODAY'],
                  valueChain: translations['CONTENT.VIDEOS.VALUECHAIN']
              },
              images: {
                  today: translations['CONTENT.IMAGES.TODAY'],
                  today_activity: translations['CONTENT.IMAGES.TODAY_ACTIVITY'],
                  today_results_1: translations['CONTENT.IMAGES.TODAY_RESULTS_1'],
                  today_results_2: translations['CONTENT.IMAGES.TODAY_RESULTS_2'],
                  today_clients: translations['CONTENT.IMAGES.TODAY_CLIENTS'],
                  today_customers: translations['CONTENT.IMAGES.TODAY_CUSTOMERS'],
                  energy: translations['CONTENT.IMAGES.ENERGY']
              },
              pdf: {
                  promise: translations['CONTENT.PDF.PROMISE'],
                  guide: translations['CONTENT.PDF.GUIDE'],
                  safety: translations['CONTENT.PDF.SAFETY']
              }
          };

      });
  });


  $scope.getUrlLang = function(){
      $scope.url = $location.path();
      $scope.urlLangTab = $scope.url.split('/');
      $scope.urlLang = $scope.urlLangTab[$scope.urlLangTab.length - 1];

      if($scope.urlLang == 'home' || $scope.urlLang == null || $scope.urlLang == '' || $scope.urlLang == 'en') {  // si pas de langue
          $scope.urlLang = 'en';  //  on met "en" comme valeur
          document.querySelectorAll('.lang-selector')[0].selectedIndex = "0";  // et on set sur le select sur l'index correspondant à la langue "en", ici 0
      }

      return $scope.urlLang;
  }


  $rootScope.$on('$locationChangeStart', function() {

      /* Gestion du lien du header du site en fonction de la langue */
      $scope.homeLink = 'home';
      $scope.urlLang = $scope.getUrlLang();
      console.log($scope.urlLang);
      if($scope.urlLang != null || $scope.urlLang != '') $scope.homeLink = 'home/' + $scope.urlLang;
      if($scope.urlLang == 'home' || $scope.urlLang == null || $scope.urlLang == '' || $scope.urlLang == 'en') {  // si pas de langue
          $scope.urlLang = 'en';  //  on met "en" comme valeur
          document.querySelectorAll('.lang-selector')[0].selectedIndex = "0";  // et on set sur le select sur l'index correspondant à la langue "en", ici 0
      }
      $translate.use($scope.urlLang);
  });


  $rootScope.$on('$locationChangeSuccess', function() {

     /* var optionSelected = '.lang-selector option[value=' + '"' + $scope.urlLang + '"' + ']';
      var allOptions = '.lang-selector option';*/

     /* $(allOptions).removeAttr("selected");
      $(optionSelected).attr("selected", "selected");*/

  });




  /*
    *
    * TRANSLATE FUNCTION
    *
    * # Au clic sur un bouton de changement de langue :
    * - On charge une nouvelle langue
    * - On recupere l'url de notre route
    * - On recupere la langue présente dans l'url
    * - S'il n'y a pas de langue dans l'url, on ajoute à l'url la langue du bouton sur lequel on vient de cliquer, et on redirige vers cette meme page, avec la langue en plus.
    * - S'il y a une langue dans l'url, on remplace la langue présente dans l'url pour la changer par celle sur laquelle on vient de cliquer, et on redigirige vers cette page.
    *
   */
  self.translate = function(lang){
      $('body, html').fadeOut(0);
      $translate.use(lang);
      $scope.local = $routeParams.local;
      $scope.url = $location.path();
      $scope.urlLangTab = $scope.url.split('/');
      $scope.urlLang = $scope.urlLangTab[$scope.urlLangTab.length - 1];

      if($scope.urlLang == '') {
          $scope.urlLang = lang;
          $scope.destUrl = '/#!' + $scope.url + $scope.urlLang;
          $window.location.href = $scope.destUrl;
      }else {
          $scope.destUrl = $scope.url.replace($scope.urlLang, lang);
          $scope.destUrl = '/#!' + $scope.destUrl;
          $window.location.href = $scope.destUrl;
      }
      $('body, html').fadeIn(1000);

  };

  //$scope.langSelectValue = 'en';


  /*
    Variable controlant la visibilité du site
  */
  self.isVisible = false;
  /*
  Sert à afficher ou cacher le menu '.PageNav'
  */
  self.navIsVisible = false;
  self.displayNav = function(doIt){
    if(doIt) { self.navIsVisible = !self.navIsVisible; }
  };
  /*
    Sert à remonter en haut d'une page
  */
  self.scrollTop = function(){
    TweenMax.to($(window), 1, {scrollTo:{y:0}});
  };
  /*
    Affiche ou non le bouton servant à remonter en haut d'une page
  */
  self.backTopVisible = false;
  $(window).on('scroll', function(){
    var dist = $(window).scrollTop(),
      oldVal = self.backTopVisible,
      newVal = (dist > 400)?true:false;
    if( oldVal !== newVal ){
      self.backTopVisible = newVal;
      $scope.$apply();
    }
  });
  /*
    Je rends visible mon application après avoir lancé un évènement resize, celui-ci va m'aider à calculer correctement la position de mes blocs en JS. Sinon les calculs exécutés via $('.nav').height() par exemple me donne des résultats faux.
  */
  $timeout(function () {
    angular.element($window).resize();
    self.isVisible = true;
  }, 100);
}]).


/*
  Directive servant à transférer les informations de hauteur/largeur de l'ecran via l'évènement 'resize:resize'
*/
directive('resize', ['$window', '$document', function ($window, $document) {
    return function (scope, element, attrs) {
      var wait = 0;
      attrs.$observe('resize', function(newWait){
        wait = $window.parseInt(newWait || 0);
        angular.element($window).on('resize', $window._.debounce(function() {
          var w = $window,
              x = w.innerWidth,
              y = w.innerHeight;
          scope.$broadcast('resize::resize', {
            innerWidth: x,
            innerHeight: y
          });
        }, wait));
      });
    }
}]).
directive('navVisible', ['$animate', function($animate){
  return function(scope, element, attrs){
    scope.$on('resize::resize', function(event, args){
      if(scope.$eval(attrs.navVisible) === true) {
        $(element).css('transform', 'matrix(1,0,0,1,'+(+args.innerWidth-$(element).width())+',0)');
      } else {
        $(element).css('transform', 'matrix(1,0,0,1,'+args.innerWidth+',0)');
      }
    });
    scope.$watch(attrs.navVisible, function(newVal){
      if(newVal) {
        $animate.removeClass(element, 'navDisplayed');
      } else {
        $animate.addClass(element, 'navDisplayed');
      }
    });
  }
}]).

animation('.navDisplayed', ['$window', function($window){
  return {
    addClass: function(element, className, done){
      TweenMax.to(element, .8, {x: +$window.innerWidth, ease:Expo.easeOut, onComplete:done});
    },
    removeClass: function(element, className, done){
      TweenMax.to(element, .8, {x: +$window.innerWidth-$(element).width(), ease:Expo.easeOut, onComplete:done});
    }
  }
}]).

animation('.btnScrollDisplayed', ['$window', function($window){
  return {
    enter: function(element, className, done){
      TweenMax.to(element, .8, {alpha: 1, ease:Expo.easeOut, onComplete:done});
    },
    leave: function(element, className, done){
      TweenMax.to(element, .8, {alpha: 0, ease:Expo.easeOut, onComplete:done});
    }
  }
}]);
